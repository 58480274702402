import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import Menu from './assets/images/Menu.svg'
import Logo from './assets/images/Logo.svg'
import Close from './assets/images/Close.svg'
import Cover from './Cover'
import Contact from './Contact'
import Concept from './Concept'
import Services from './Services'
import Andrea from './Andrea'
import Faq from './Faq'
import { MediasIcons, Paragraph, RequestButton, SubmitButton } from './shared/Components'
import Modal from "./Modal"
import { useTranslation } from 'react-i18next'

const Home = () => {
  const { t, i18n } = useTranslation();

  const [menuOuvert, setMenuOuvert] = useState(false)
  const [modal, setModal] = useState(false)

  const homeRef = useRef(null)
  const contactRef = useRef(null)
  const conceptRef = useRef(null)
  const servicesRef = useRef(null)
  const andreaRef = useRef(null)
  const faqRef = useRef(null)

  const scrollToRef = (ref, closeMenu = false) => {
     if (ref && ref.current) {
      const elementPosition = ref.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - 85; // Ajuste la position par la hauteur du défilement actuel et le décalage souhaité

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      })
    }
    if (closeMenu) {
      setMenuOuvert(false);
    }
  }

  const toggleMenu = () => {
    setMenuOuvert(!menuOuvert);
  }

  return (
    <MainWrapper>
      <Language onClick={() => i18n.changeLanguage(i18n.language === 'fr' ? 'en' : 'fr')}>{i18n.language === 'fr' ? 'EN' : 'FR'}</Language>

      <Navbar>
        <img src={Menu} alt="menu" className="pointer" onClick={toggleMenu}/>
        <img src={Logo} alt="Angleys" className="pointer" id="logo" onClick={() => scrollToRef(homeRef)}/>
        <RequestButton component="navbar" onClick={() => scrollToRef(contactRef)}>{t('request')}</RequestButton>
      </Navbar>

      { menuOuvert && (
        <MenuOverlay>
          <div>
            <img className="pointer" src={Close} alt="close-button" onClick={toggleMenu} />
            <ul>
              <li className="pointer" onClick={() => scrollToRef(conceptRef, true)}>Concept</li>
              <li className="pointer" onClick={() => scrollToRef(servicesRef, true)}>{t('services')}</li>
              <li className="pointer" onClick={() => scrollToRef(andreaRef, true)}>{t('perfumer')}</li>
              <li className="pointer" onClick={() => scrollToRef(contactRef, true) }>Contact</li>
              {/* <li className="pointer">FAQ</li> */}
            </ul>
          </div>
          <SubmitButton onClick={() => setModal(true)}>
            {t('request')}
          </SubmitButton>
        </MenuOverlay>
      )}

      {
        modal && <Modal modal={modal} setModal={setModal} />
      }

      <Cover
        conceptRef={conceptRef}
        ref={homeRef}
        setModal={setModal}
        scrollToRef={scrollToRef}
        contactRef={contactRef}
      />

      <Concept ref={conceptRef} />

      <Services
        ref={servicesRef}
        contactRef={contactRef}
        setModal={setModal}
        scrollToRef={scrollToRef}
      />

      <Andrea ref={andreaRef} />

      <Contact ref={contactRef} />

      {/* <Faq ref={faqRef} /> */}

      <Footer>
        <img src={Logo} alt="Angleys" className="pointer" id="logo"/>
        <Paragraph>Your Soul. Your Scent.</Paragraph>
        <MediasIcons />
      </Footer>
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  font-family: 'Butler';
  color: white;

  position: relative;

  .pointer {
    cursor: pointer;
  }

  #logo {
    height: 30px;
  }
`

const Navbar = styled.div`
  height: 85px;
  padding: 0px 112px;
  background-color: rgba(0, 0, 0, 0.32);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: background-color 0.3s, backdrop-filter 0.3s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;

  #logo {
    margin-left: 74px;
  }

  @media (max-width: 480px) {
    height: 56px;
    padding: 0px 16px;

    #logo {
      height: 24px;
      margin-left: 0px;
      margin-right: 136px;
    }
  }
`;

const MenuOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  transition: 3s;
  z-index: 1000;
  width: 20%;
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img {
    margin-bottom: 40px;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    padding: 10px 0;
    color: white;
  }

  @media (max-width: 480px) {
    width: 50%;
  }
`

const Footer = styled.div`
  background-color: #080808;
  width: 100%;
  height: 426px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  #logo {
    margin-bottom: 40px;
  }

  @media (max-width: 480px) {
    height: 300px;
  }
`

const Language = styled.button`
  position: fixed;
  top: 32px;
  right: 40px;
  z-index: 1000;
  border: none;
  background-color: transparent;
  color: white;
  cursor: pointer;

  @media (max-width: 480px) {
    top: 18px;
  }
`

export default Home
