import React, { forwardRef } from "react"
import styled from "styled-components"
import { MainHeader, MediasIcons, Paragraph, SharedWrapper, SubHeader } from "./shared/Components"
import AndreaPic from "./assets/images/AndreaPic.png"
import { useTranslation } from 'react-i18next'

const Andrea = forwardRef((props, ref) => {
  const { t } = useTranslation();
  return (
    <AndreaWrapper ref={ref}>
      <div className="left-block">
        <MainHeader>{t('perfumer')}.</MainHeader>
        <SubHeader>ANDREA DE LASSUS</SubHeader>
        <ParagraphAndrea>{t('andrea-1')}</ParagraphAndrea>
        <ParagraphAndrea>{t('andrea-2')}</ParagraphAndrea>
        <ParagraphAndrea>{t('andrea-3')}</ParagraphAndrea>
        <ParagraphAndrea>{t('values')}</ParagraphAndrea>
      </div>
    </AndreaWrapper>
  )
})

const AndreaWrapper = styled(SharedWrapper)`
  background-image: url(${AndreaPic});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left 112px top 0;
  display: flex;
  height: 1000px;

  img {
    width: 100%;
  }

  .left-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 36px 80px 36px 112px;
    height: calc(100% - 72px);
    width: calc(50% - 194px);
    background-color: rgba(0, 0, 0, 0.72);
    backdrop-filter: blur(10px);
    overflow-y: scroll;
  }

  @media (min-width: 480px) {
    justify-content: flex-start;
  }

  @media (max-width: 480px) {
    justify-content: center;
    background-position: right 28% top 0;
    .left-block {
      padding: 32px;
      width: 70%;
      height: fit-content;
    }
  }
`

const ParagraphAndrea = styled(Paragraph)`
  display: flex;
  justify-content: center;
  margin: 40px 0px;

  @media (max-width: 480px) {
    margin: 20px 0px;
  }
`

export default Andrea
