import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import { InfoButton, SubmitButton, WhiteBar } from "./shared/Components"
import { FormWrapper } from "./Contact"
import emailjs from '@emailjs/browser'
import { useTranslation } from 'react-i18next'

const Modal = ({ modal, setModal }) => {
  const [selectedOption, setSelectedOption] = useState('')
  const modalContentRef = useRef(null)
  const { t } = useTranslation()

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  }

  const handleClickOutside = (event) => {
    if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
      if (modal === true) { setModal(null) }
    }
  }

  const form = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault()

    emailjs.sendForm('service_2s4sged', 'template_chft7a9', form.current, '94dTiTgjd3VChHxkh')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text)
        setModal(null)
      }, (err) => {
        console.log('FAILED...', err);
      })
  }

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => window.removeEventListener('mousedown', handleClickOutside);
  }, [modal, modalContentRef])

  return (
    <ModalWrapper>
      <ModalContent ref={modalContentRef}>
        <div>{t('request')}</div>
        <div>{t('request-desc')}</div>
        <WhiteBar />
        <form onSubmit={handleSubmit} ref={form}>
          <div>TYPE</div>
          <RadioButton>
            <label>
              <input
                type="radio"
                value="semi-mesure"
                checked={selectedOption === 'semi-mesure'}
                onChange={handleChange}
                name="type"
              />
              <span></span>
              {t('semi-custom')}
            </label>
            <br />
            <label>
              <input
                type="radio"
                value="sur-mesure"
                checked={selectedOption === 'sur-mesure'}
                onChange={handleChange}
                name="type"
              />
              <span></span>
              {t('custom')}
            </label>
          </RadioButton>
          <ModalFormWrapper>
            <label>{t('name')}
              <input
                type="text"
                name="name"
                placeholder={t('name-placeholder')}
                required
              />
            </label>
            <label>EMAIL
              <input
                type="email"
                name="email"
                placeholder="Email"
                required
              />
            </label>
            <label>{t('phone')}
              <input
                type="text"
                name="phone"
                placeholder={t('phone-placeholder')}
              />
            </label>
            <div>
              <InfoButtonModal onClick={() => setModal(null)}>{t('back')}</InfoButtonModal>
              <SubmitButton type="submit" value="Send">{t('send')}</SubmitButton>
            </div>
          </ModalFormWrapper>
        </form>
      </ModalContent>
    </ModalWrapper>
  )
}

const ModalWrapper = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  z-index: 99999;
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 544px;
  background-color: var(--grey-color);
  padding: 24px;
  > div:nth-of-type(1) {
    font-size: 32px;
    margin-bottom: 8px;
  }

  @media (max-width: 480px) {
    width: 80%;
  }
`

const ModalFormWrapper = styled(FormWrapper)`
  label:last-of-type {
    input {
      height: auto;
    }
  }

  div {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
`

const RadioButton = styled.div`
  margin-bottom: 16px;

  input[type="radio"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  label {
    display: inline-block;
    padding-left: 25px;
    position: relative;
    cursor: pointer;
    user-select: none;
  }

  label span::before {
    content: '';
    position: absolute;
    left: 0;
    top: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid var(--grey-secondary-color);
    background-color: var(--dark-grey);
  }

  input[type="radio"]:checked + span::before {
    background-color: white;
  }
`

const InfoButtonModal = styled(InfoButton)`
  width: 129px;
  margin-right: 12px;
`

export default Modal
