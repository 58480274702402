import React, { forwardRef } from "react"
import styled from "styled-components"
import { InfoButton, MainHeader, Paragraph, SharedWrapper, SubHeader, SubmitButton, TitledContentWrapper } from "./shared/Components"
import SemiMesure from "./assets/images/SemiMesure.jpg"
import SurMesure from "./assets/images/SurMesure.jpg"
import Professionnels from "./assets/images/Professionnels.jpg"
import Inities from "./assets/images/Inities.jpg"
import { useTranslation } from 'react-i18next'

const Services =  forwardRef((props, ref) => {
  const {contactRef, setModal, scrollToRef} = props
  const { t } = useTranslation();

  return (
    <ServicesWrapper ref={ref}>
      <MainHeader>{t('services')}</MainHeader>
      <TitledContentWrapper>
        <Boxes>
          <ServiceBox className="semi-mesure">
            {t('semi-custom').toUpperCase()}
            <CoverDiv className="semi-mesure">
              <SubHeader>{t('semi-custom').toUpperCase()}</SubHeader>
              <ServiceParagraph>{t('semi-custom-desc')}</ServiceParagraph>
              <SubmitButton onClick={() => setModal(true)}>{t('request')}</SubmitButton>
            </CoverDiv>
          </ServiceBox>
          <ServiceBox className="sur-mesure">
            {t('custom').toUpperCase()}
            <CoverDiv className="sur-mesure">
              <SubHeader>{t('custom').toUpperCase()}</SubHeader>
              <ServiceParagraph>{t('custom-desc')}</ServiceParagraph>
              <SubmitButton onClick={() => setModal(true)}>{t('request')}</SubmitButton>
            </CoverDiv>
          </ServiceBox>
          <ServiceBox className="professionnels">
            {t('professionals').toUpperCase()}
            <CoverDiv className="professionnels">
              <SubHeader>{t('professionals').toUpperCase()}</SubHeader>
              <ServiceParagraph>{t('pro-desc')}</ServiceParagraph>
              <SubmitButton onClick={() => scrollToRef(contactRef)}>Contact</SubmitButton>
            </CoverDiv>
          </ServiceBox>
          <ServiceBox className="inities">
            {t('insiders').toUpperCase()}
            <CoverDiv className="inities">
              <SubHeader>{t('insiders').toUpperCase()}</SubHeader>
              <ServiceParagraph>{t('insiders-desc')}</ServiceParagraph>
              <InfoButton disabled={true} onClick={() => setModal(true)}>{t('join')}</InfoButton>
            </CoverDiv>
          </ServiceBox>
        </Boxes>
      </TitledContentWrapper>
    </ServicesWrapper>
  )
})

const ServicesWrapper = styled(SharedWrapper)`
  padding: 36px 112px;
  display: flex;
  flex-direction: column;
  gap: 36px;
  height: fit-content;

  @media (max-width: 480px) {
    padding: 48px 16px;
  }
`

const Boxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  width: 100%;
  height: 100%;

  .semi-mesure {
    background-image: url(${SemiMesure});
  }
  .sur-mesure {
    background-image: url(${SurMesure});
  }
  .professionnels {
    background-image: url(${Professionnels});
  }
  .inities {
    background-image: url(${Inities});
  }

  @media (max-width: 480px) {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }
`

const ServiceBox = styled.div`
  position: relative;
  width: calc(50% - 2px);
  height: 320px;
  border: 1px solid black;
  font-size: 22px;
  font-family: 'Overpass';
  letter-spacing: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  transition: all 0.3s ease;

  @media (max-width: 480px) {
    letter-spacing: 4px;
    font-size: 18px;
  }

  > div {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
    box-sizing: border-box;
    pointer-events: none; /* Empêcher le paragraphe de capturer les événements de souris */
  }

  &:hover > div {
    opacity: 1; /* Rendre le paragraphe complètement opaque */
    pointer-events: auto; /* Permettre les interactions avec le paragraphe */
  }

  @media (max-width: 480px) {
    width: 100%;
    font-size: 24px;
    height: 352px;
  }
`;

const CoverDiv = styled(Paragraph)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  background-size: cover;
  background-position: center;
  padding: 36px 44px;
  .semi-mesure {
    background-image: url(${SemiMesure});
  }
  .sur-mesure {
    background-image: url(${SurMesure});
  }
  .professionnels {
    background-image: url(${Professionnels});
  }
  .inities {
    background-image: url(${Inities});
  }
`

const ServiceParagraph = styled(Paragraph)`
  text-align: center;
  font-family: 'Butler';
  letter-spacing: 0;
`

export default Services
