import React, { forwardRef } from "react"
import styled from "styled-components"
import { MainHeader, Paragraph, SharedWrapper, SubHeader } from "./shared/Components"
import Appartment from "./assets/images/Appartment.png"
import { useTranslation } from 'react-i18next'

const Concept = forwardRef((props, ref) => {
  const { t } = useTranslation()

  return (
    <ConceptWrapper ref={ref}>
      <div className="right-block">
        <MainHeader>Concept.</MainHeader>
        <SubHeader>{t('concept-subtitle').toUpperCase()}</SubHeader>
        <ParagraphConcept>{t('concept-1')}</ParagraphConcept>
        <ParagraphConcept>{t('concept-2')}</ParagraphConcept>
        <ParagraphConcept>{t('moto')}</ParagraphConcept>
      </div>
    </ConceptWrapper>
  )
})

const ConceptWrapper = styled(SharedWrapper)`
  background-image: url(${Appartment});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-end;
  background-position: bottom;

  img {
    width: 100%;
  }

  .right-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 36px 112px 36px 80px;
    height: calc(100% - 72px);
    width: calc(50% - 194px);
    background-color: rgba(0, 0, 0, 0.72);
    backdrop-filter: blur(10px);
    overflow-y: scroll;
  }

  @media (max-width: 480px) {
    justify-content: center;
    .right-block {
      padding: 32px;
      width: 70%;
      height: fit-content;
    }
  }
`

const ParagraphConcept = styled(Paragraph)`
  width: 100%;
  justify-content: center;
  margin: 40px 0px;

  @media (max-width: 480px) {
    margin: 20px 0px;
  }
`

export default Concept
