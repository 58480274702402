import React, { forwardRef } from "react"
import styled from "styled-components"
import CoverPic from './assets/images/CoverPic.png'
import Flacon from './assets/images/Flacon.png'
import { SharedWrapper, SubHeader, RequestButton } from "./shared/Components"
import { useTranslation } from 'react-i18next'

const Cover = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const { contactRef, scrollToRef } = props

  return (
    <CoverWrapper ref={ref}>
      <FlexWrapper>
        <img id="flacon" src={Flacon} alt="parfum" className='logo'/>
        <CoverSubHeader>BESPOKE PERFUME</CoverSubHeader>
        <RequestButton component="cover" onClick={() => scrollToRef(contactRef)}>{t('request')}</RequestButton>
      </FlexWrapper>
    </CoverWrapper>
  )
})

const CoverSubHeader = styled(SubHeader)`
  font-size: 34px;
  letter-spacing: 12px;

  @media (max-width: 480px) {
    font-size: 24px;
    letter-spacing: 6px;
  }
  font-weight: bolder;
`


const CoverWrapper = styled(SharedWrapper)`
  background-image: url(${CoverPic});
  background-size: cover;
  background-repeat: no-repeat;
  padding: 36px 112px;
  display: flex;
  @media (max-width: 480px) {
    padding: 0px 16px;
  }
`

const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  #flacon {
    height: 360px;
  }

  @media (min-width: 481px) {
    height: 500px;
  }

  @media (max-width: 480px) {
    height: 550px;
    flex-direction: column;

    #flacon {
      img {
        height: 280px
      }
    }
  }
`

export default Cover
