import React, { useRef, forwardRef, useState } from "react";
import styled from "styled-components";
import {
  MainHeader,
  MapImage,
  Paragraph,
  SharedWrapper,
  SubHeader,
  SubmitButton,
  TitledContentWrapper,
  WhiteBar } from './shared/Components'
import Location from './assets/images/Location.svg'
import Phone from './assets/images/Phone.svg'
import Mail from './assets/images/Mail.svg'
import Clock from './assets/images/Clock.svg'
import emailjs from '@emailjs/browser'
import { useTranslation } from 'react-i18next'

const Contact = forwardRef((props, ref) => {
  const [sent, setSent] = useState(false)
  const form = useRef()
  const { t } = useTranslation()

  const handleSubmit = async (e) => {
    e.preventDefault()

    emailjs.sendForm('service_2s4sged', 'template_8xnj76p', form.current, '94dTiTgjd3VChHxkh')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text)
        setSent(true)
      }, (err) => {
        console.log('FAILED...', err);
      })
  }

  return (
    <ContactWrapper ref={ref}>
      <MainHeader>Contact.</MainHeader>
      <ContactContent>
        <ContactBlock>
            {
              !sent ? (
                <FormWrapper>
                  <form onSubmit={handleSubmit} ref={form}>
                    <label>EMAIL
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          required
                        />
                    </label>
                    <label>{t('subject')}
                        <input
                          type="text"
                          name="subject"
                          placeholder={t('subject-placeholder')}
                        />
                    </label>
                    <label>MESSAGE
                        <textarea
                          type="message"
                          name="message"
                          placeholder={t('write-your-message')}
                          required
                        />
                    </label>
                    <ContactButton type="submit" value="Send">{t('send')}</ContactButton>
                  </form>
                </FormWrapper>
              ) : (
                <Thanks>{t('thank-you')}</Thanks>
              )
            }
          {/* <ContactBlock>
            <SubHeader>{t('find-us')}</SubHeader>
            <Paragraph><img src={Location} alt="Location" className='logo'/>9 rue Chanez 75016 Paris</Paragraph>
            <Paragraph><img src={Phone} alt="Phone" className='logo'/>{'+33 (0)1 47 94 43 36'}</Paragraph>
            <Paragraph><img src={Mail} alt="Mail" className='logo'/>angleys@bespokeperfume.fr</Paragraph>
            <Paragraph><img src={Clock} alt="Clock" className='logo'/>{t('open')}</Paragraph>
            <MapImage/>
          </ContactBlock> */}
        </ContactBlock>
      </ContactContent>
    </ContactWrapper>
  )
})

const ContactWrapper = styled(SharedWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 36px 112px;
  height: 600px;

  @media (max-width: 480px) {
    padding: 80px 32px 80px 32px;
    height: fit-content;
  }
`

const ContactContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ContactBlock = styled.div`
  @media (min-width: 480px) {
    width: 576px;
  }
  @media (max-width: 480px) {
    width: 320px;
  }
`

const ContactButton = styled(SubmitButton)`
  width: 100%;
`

export const FormWrapper = styled.div`
  label {
    font-family: 'Overpass';
    display: flex;
    flex-direction: column;
    font-size: 13px;
    margin-bottom: 16px;
  }

  input, textarea {
    margin-top: 4px;
    background-color: #3C3C3C;
    border: 1px solid #828282;
    color: white;
    font-size: 16px;
    padding: 12px 12px 12px 16px;

    &:hover {
      border: 1px solid #949494;
    }

    &:focus {
      border: 1px solid #3A6EAA;
      outline: none;
    }

    &::placeholder {
      color: #868686;
      font-family: 'Overpass';
    }

    &:valid {
      background-color: #3C3C3C;
      border: 1px solid #828282;
    }
  }

  textarea {
    height: 131px;
  }

  label:last-of-type {
    input {
      height: 131px;
    }
  }
`

const Thanks = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
`

export default Contact
