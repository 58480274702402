import React, { useState, forwardRef } from "react"
import styled from "styled-components"
import { MainHeader, MinusIcon, Paragraph, PlusIcon, SharedWrapper, SubHeader, TitledContentWrapper, WhiteBar } from "./shared/Components"

const Faq = forwardRef((props, ref) => {
  const [time, setTime] = useState(false)
  const [how, setHow] = useState(false)
  const [reorder, setReorder] = useState(false)
  const [gift, setGift] = useState(false)
  const [conservation, setConservation] = useState(false)

  return (
    <FaqWrapper ref={ref}>
      <MainHeader>FAQ</MainHeader>
      <WhiteBar/>
      <FaqContentWrapper>
        <FaqBlock>
          <div>
            <FaqHeader>Combien de temps prend la création ?</FaqHeader>
            <Icon onClick={() => setTime(!time)}>
              { time ? <MinusIcon /> :  <PlusIcon /> }
            </Icon>
          </div>
          {
            time &&
              <Paragraph>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pulvinar ligula eu vestibulum malesuada.
              </Paragraph>
          }
          <WhiteBar />
        </FaqBlock>

        <FaqBlock>
          <div>
            <FaqHeader>Comment récupérer la création ?</FaqHeader>
            <Icon onClick={() => setHow(!how)}>
              { how ? <MinusIcon /> :  <PlusIcon /> }
            </Icon>
          </div>
          {
            how &&
              <Paragraph>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pulvinar ligula eu vestibulum malesuada.
              </Paragraph>
          }
          <WhiteBar />
        </FaqBlock>

        <FaqBlock>
          <div>
            <FaqHeader>Puis-je recommander ma création ?</FaqHeader>
            <Icon onClick={() => setReorder(!reorder)}>
              { reorder ? <MinusIcon /> :  <PlusIcon /> }
            </Icon>
          </div>
          {
            reorder &&
              <Paragraph>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pulvinar ligula eu vestibulum malesuada.
              </Paragraph>
          }
          <WhiteBar />
        </FaqBlock>

        <FaqBlock>
          <div>
            <FaqHeader>Puis-je offrir une création ?</FaqHeader>
            <Icon onClick={() => setGift(!gift)}>
              { gift ? <MinusIcon /> :  <PlusIcon /> }
            </Icon>
          </div>
          {
            gift &&
              <Paragraph>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pulvinar ligula eu vestibulum malesuada.
              </Paragraph>
          }
          <WhiteBar />
        </FaqBlock>

        <FaqBlock>
          <div>
            <FaqHeader>Combien de temps conserver la création ?</FaqHeader>
            <Icon onClick={() => setConservation(!conservation)}>
              { conservation ? <MinusIcon /> :  <PlusIcon /> }
            </Icon>
          </div>
          {
            conservation &&
              <Paragraph>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pulvinar ligula eu vestibulum malesuada.
              </Paragraph>
          }
          <WhiteBar />
        </FaqBlock>
      </FaqContentWrapper>
    </FaqWrapper>
  )
})

const FaqWrapper = styled(SharedWrapper)`
  padding: 36px 112px;
  background-color: var(--dark-grey);
`

const FaqContentWrapper = styled(TitledContentWrapper)`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const FaqBlock = styled.div`
  width: 90%;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const FaqHeader = styled(SubHeader)`
  margin-bottom: 0px;
`

const Icon = styled.div`
  &:hover {
    cursor: pointer;
  }
`

export default Faq
