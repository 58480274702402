// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/Butler_Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/Overpass-Thin.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Butler';
  src: local('Butler Regular'), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('opentype');
}

@font-face {
  font-family: 'Overpass';
  src: local('Overpass Thin'), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
}

:root {
  --primary-color: #4D7877;
  --secondary-color: #0B4B45;
  --grey-color: #2B2B2B;
  --grey-secondary-color: #5E5E5E;
  --dark-grey: #1A1A1A;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,wFAAyF;AAC3F;;AAEA;EACE,uBAAuB;EACvB,uFAAuF;AACzF;;AAEA;EACE,wBAAwB;EACxB,0BAA0B;EAC1B,qBAAqB;EACrB,+BAA+B;EAC/B,oBAAoB;AACtB","sourcesContent":["@font-face {\n  font-family: 'Butler';\n  src: local('Butler Regular'), url('./assets/fonts/Butler_Regular.otf') format('opentype');\n}\n\n@font-face {\n  font-family: 'Overpass';\n  src: local('Overpass Thin'), url('./assets/fonts/Overpass-Thin.ttf') format('truetype');\n}\n\n:root {\n  --primary-color: #4D7877;\n  --secondary-color: #0B4B45;\n  --grey-color: #2B2B2B;\n  --grey-secondary-color: #5E5E5E;\n  --dark-grey: #1A1A1A;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
