import React from 'react';
import './i18n';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import Home from './Home.js';
import './index.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home/>} />
      </Routes>
    </Router>
  );
}


export default App;
